<template>
<div class="box-white-fiche" v-if="show_header(1)">
    <div class="row-column">
        <div class="col-child icon-status">
            <div class="bx-symbol">
                <div class="icon" v-if="data.icon_flags">
                    <img :alt="data.agency_model" :src="require('../../../assets/images/flag/new/'+data.icon_flags+'.svg')" width="30" height="30" style="margin: auto;" />
                    <div class="status">{{$i18n.locale === 'fr' ? data.acronym_fr : data.acronym_en}}</div>
                </div>
            </div>
        </div>
        <div class="col-child" v-if="data.name">
            <div class="status-box" >
                <h3>{{$t('Drug name')}}</h3>
                <p >{{data.name}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.dci">
            <div class="status-box" >
                <h3>{{$t('DCI')}}</h3>
                <p>{{data.dci}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.firm">
            <div class="status-box" >
                <h3>{{$t('firm')}}</h3>
                <p>{{data.firm}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.indexation">
            <div class="status-box" >
                <h3>{{$t('Therapeutic area')}}</h3>
                <p v-html="array(data.indexation)"></p>
            </div>
        </div>
        <div class="col-child"  v-if="data.atc">
            <div class="status-box" >
                <h3>{{$t('ATC')}}</h3>
                <p v-html="array(data.atc)"></p>
            </div>
        </div>
        <div class="col-child"  v-if="data.mk_orphelin">
            <div class="status-box" >
                <h3>{{$t('Orphan designation')}}</h3>
                <p>{{$t(data.mk_orphelin)}}</p>
            </div>
        </div>
    </div>
    <PDFModifyButton :agency="data.acronym_en"/>
</div>

<div class="box-white-fiche" v-if="show_header(2)">
    <div class="row-column">
        <div class="col-child"  v-if="data.current_ma_status && data.current_ma_status['decision_date_num'] != data.decision_date_num">
            <div class="status-box">
                <h3 style="color : #000;">{{$t('Current MA status')}}</h3>                               
                <span class="prismheor-status-box" v-html="overview_regulatory_status(data.current_ma_status)"> </span>
            </div>
        </div>
        <div class="col-child" v-if="data.ma_date && data.agency_type != 'Regulatory' && (!data.current_ma_status || (data.current_ma_status && !(['Positive', 'Approved'].includes(data.current_ma_status['decision_mapping'])  && data.current_ma_status['decision_date_num'] == data.ma_date)))">
            <div class="status-box">
                <h3 style="color : #000;">{{$t('approval date (MA)')}}</h3>
                <p>{{ DDMMMYY(data.ma_date)}} </p>
            </div>
        </div>
        <div class="col-child"  style="text-align:center;" v-if="(data.agency_type.indexOf('Early access') > -1 || data.agency_type.indexOf('HTA') > -1 ) && ((data.hta_decision && data.hta_decision != 'ATU')|| data.hta_date)">
            <div class="status-box">
                <h3 style="color : #000;">{{data.agency_type.indexOf('Early access') > -1 ? $t('atufrance.HTA') : 'HTA'}}</h3>
                <span class="prismheor-status-box" v-html="hta_process_status_2(data)"></span>
            </div>
        </div>
        <div class="col-child"  style="text-align:center;" v-if="(data.agency_type.indexOf('Early access') > -1 || data.agency_type.indexOf('Reimbursement') > -1) && (data.reimbursement_decision || data.reimbursement_date)">
            <div class="status-box">
                <h3 style="color : #000;">{{data.agency_type.indexOf('Early access') > -1 ? $t('atufrance.Reimbursement') : $t('Reimbursement')}}</h3>
                <span class="prismheor-status-box" v-html="rembursement_status_2(data)"></span>
            </div>
        </div>
        <template v-if="data.agency_type && data.agency_type.indexOf('Reimbursement') == -1 && data.agency_type.indexOf('HTA') == -1 && data.agency_type.indexOf('Early access') == -1">
            <div class="col-child"  style="text-align:center;" v-if="data.decision_mapping || data.decision">
                <div class="status-box">
                    <h3 style="color : #000;">{{$t('Decision')}}</h3>
                    <span class="prismheor-status-box" v-html="decision_mapping_detail(data)"></span>
                </div>
            </div>
            <div class="col-child"  style="text-align:center;" v-if="data.decision_date_num">
                <div class="status-box">
                    <h3 style="color : #000;">{{$t('Decision date')}}</h3>
                    <span v-html="DDMMMYY(data.decision_date_num)"></span>
                </div>
            </div>
        </template>
        <div class="col-child" v-if="$i18n.locale == 'fr' && data['classification_fr'] || $i18n.locale != 'fr' && data['classification_en']">
            <div class="status-box">
                <h3 style="color : #000;">{{$t('Classification')}}</h3>
                <span v-if="$i18n.locale == 'fr'" v-html="array(data['classification_fr'])"></span>
                <span v-else v-html="array(data['classification_en'])"></span>
            </div>
        </div>
        <template v-for="(item, key) in key_documents" :key="'key_documents_'+key">
            <div class="col-child" >
                <div class="status-box">
                    <h3 style="color : #000;" class="prismheor-mb-0"  v-html="item['title'] ? $t(item['title']) : ''"></h3>
                    <KeyDocumentIcon :value="item" :list_document_exist="data.list_document_exist"/>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script>
import PDFModifyButton from './PDFModifyButton.vue'
import KeyDocumentIcon from '../KeyDocumentIcon.vue'
import { hta_process_status_2, rembursement_status_2, decision_mapping_detail,overview_regulatory_status, DDMMMYY, array, append_key_documents_to_list  } from '../../../utils';
export default {
    name : "HeaderForDrug",
    components : {
        PDFModifyButton,
        KeyDocumentIcon
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        translate_pdf_return(){
            return this.$store.getters['detail/translate_pdf_return']
        },
        /**
         * Get documents from data
        */
        key_documents(){
            let temp = []
            let keydoc_array = []
            for(let key in this.data.key_documents){
                if(this.data.key_documents[key]['type'] && this.data.key_documents[key]['type'].toLowerCase() == 'guidance'){
                    temp, keydoc_array = append_key_documents_to_list(temp, keydoc_array, this.data.key_documents[key])
                }
            }
            return keydoc_array.sort(function(a,b) {
                var nameA = a.title.toUpperCase(); // ignore upper and lowercase
                var nameB = b.title.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            } )
        }
    },
    methods : {
        overview_regulatory_status,
        hta_process_status_2,
        rembursement_status_2,
        decision_mapping_detail,
        DDMMMYY,
        array,
        append_key_documents_to_list,
        translate_pdf(path){
            path = path.split('/')
            this.$store.dispatch('detail/translate_pdf', {id:path[3], drug_type:path[2], file_for_translate:path[4].split('.')[0], language:this.$i18n.locale})
        },
        translate_loading(file) { 
            file = file.split('/')
            return this.$store.getters['detail/translate_loading'][`${file[2]}/${file[3]}/${file[4].split('.')[0]}`]
        },
        show_header(index){
            if(index===1){
                return this.data.icon_flags || this.data.name || this.data.dci || this.data.firm || this.data.indexation || this.data.atc || this.data.mk_orphelin
            }
            if(index===2){
                return (this.data.current_ma_status && this.data.current_ma_status['decision_date_num'] != this.data.decision_date_num) ||
                    (this.data.ma_date && this.data.agency_type != 'Regulatory' && (!this.data.current_ma_status || (this.data.current_ma_status && !(['Positive', 'Approved'].includes(this.data.current_ma_status['decision_mapping'])  && this.data.current_ma_status['decision_date_num'] == this.data.ma_date)))) ||
                    (this.data.agency_type && (this.data.agency_type.indexOf('Early access') > -1 || this.data.agency_type.indexOf('HTA') > -1 ) && (this.data.hta_decision || this.data.hta_date)) ||
                    (this.data.agency_type && (this.data.agency_type.indexOf('Early access') > -1 || this.data.agency_type.indexOf('Reimbursement') > -1) && (this.data.reimbursement_decision || this.data.reimbursement_date)) ||
                    (this.data.agency_type && this.data.agency_type.indexOf('Reimbursement') == -1 && this.data.agency_type.indexOf('HTA') == -1 && this.data.agency_type.indexOf('Early access') == -1 && (this.data.decision_mapping || this.data.decision || this.data.decision_date_num)) ||
                    (this.$i18n.locale == 'fr' && this.data['classification_fr'] || this.$i18n.locale != 'fr' && this.data['classification_en']) || 
                    (this.key_documents && this.key_documents.length > 0)
            }
        },
    }
}
</script>

<style>

</style>